// import client from '@/apis/http-client'
// import Vue from 'vue'

// ========================================================================
// TODO: Remove mocking data and helper
// function when fully implemented.
import client from '@/apis/http-client'
import Vue from 'vue'
let pathUrl = `/por/api`
// const pathOrgUrl = `por/api/organization?page_size=1000`
const pathOrgUrl = `sso/rbac/organization/permission`
import {convertFileToBase64} from '../helper/helper'
import qs from 'qs'
import {departmentStatusEnum} from '@/constants/department'
import {metaEnum} from '@/constants/package'

// End: TODO
// ========================================================================

// Initial state.
const defaultState = () => {
  return {
    // Filters
    promotionOnly: false,
    filterStatus: '',
    filterHospital: '',
    filterHospitalWebsite: '',
    filterDepartment: '',
    filterSpecialty: '',

    filterCategory: '',
    textSearch: '',
    deleteId: 0,
    isDelete: false,
    // List of department
    category: [{text: 'All', value: ''}],
    statusTab: 0,

    isLoading: false,
    isLoadingBranch: false,
    departmentList: [],
    updateIdList: [],
    isPreview: false,
    currentPage: 1,
    pageSize: 25,
    total: 0,
    sortableTotal: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    orgObject: [],
    departmentListObject: [],
    symptomaticItems: [],
    sortableList: [],
    specialtyObject: [],
    prefixObject: [],
    highlights: [],
    allDepartment: [],
    department_id: '',
    specialty_id: '',
    org_codename: '',
    category_department: '',
    license_no: '',
    // department object
    departmentObject: {
      image: '',
      id: '',
      code: null,
      org_codename: '',
      category: 1,
      doctor_count: 0,
      alias: '',
      tags: [],
      symptomatic_ids: [],
      meta: {
        SEO: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        FACEBOOK: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        X: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ]
      },
      contents: [
        {
          code: 'th',
          title: '',
          description: '',
          location: '',
          cover_image: '',
          cover_image_alt: '',
          logo: '',
          logo_alt: ''
        },
        {
          code: 'en',
          title: '',
          description: '',
          location: '',
          cover_image: '',
          cover_image_alt: '',
          logo: '',
          logo_alt: ''
        }
      ],
      specialties: [],
      contact_number: '',
      office_hour: '',
      status: 0,
      create_by_user: null,
      update_by_user: null,
      organization: {
        name: ''
      }
    },
    editOrderObject: {
      image: '',
      id: '',
      code: null,
      org_codename: '',
      category: 1,
      doctor_count: 0,
      contents: [
        {
          code: 'th',
          title: '',
          description: ''
        },
        {
          code: 'en',
          title: '',
          description: ''
        }
      ],
      specialties: [],
      contact_number: '',
      status: 0,
      create_by_user: null,
      update_by_user: null
    },
    isEditMode: false,
    errorMessage: '',
    codeErrorMessage: '',
    isEditOrder: false,
    metaImageErrorMessage: {
      [metaEnum.SEO]: {
        en: '',
        th: ''
      },
      [metaEnum.FACEBOOK]: {
        en: '',
        th: ''
      },
      [metaEnum.X]: {
        en: '',
        th: ''
      }
    },
    isMetaImageError: {
      [metaEnum.SEO]: {
        en: false,
        th: false
      },
      [metaEnum.FACEBOOK]: {
        en: false,
        th: false
      },
      [metaEnum.X]: {
        en: false,
        th: false
      }
    },
    duplicateContent: {
      department: false,
      [metaEnum.SEO]: false,
      [metaEnum.FACEBOOK]: false,
      [metaEnum.X]: false
    },
    pageLink: '',
    pageLinkEn: ''
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  setTabStatus({commit}, status) {
    commit('SET_TAB_STATUS', status)
  },
  async reorderDepartmentsList({commit}, clonedBannersList) {
    commit('SET_SORTABLE_LIST', clonedBannersList)

    const temp = [...clonedBannersList]
    let resultsReorder = temp.reverse().map((object, index) => {
      return {id: object.id, priority: index}
    })
    await client.secureHttpClient.patch(`${pathUrl}/department/reorder`, {
      departments: resultsReorder
    })
  },
  async setOrgInfo({commit}, isViewOnly) {
    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=Department`
    )
    commit('SET_ORG_DETAIL', data.data)
  },
  async setViewOrgInfo({commit}, isDetail) {
    commit('SET_IS_LOADING_BRANCH', true)
    try {
      let data = await client.secureHttpClient.get(
        `${pathOrgUrl}?permission=View&category=Department`
      )
      commit('SET_IS_LOADING_BRANCH', false)
      if (isDetail) {
        commit('SET_ORG_DETAIL', data.data)
      } else {
        commit('SET_ORG_LIST', data.data)
      }
    } catch (e) {
      commit('SET_IS_LOADING_BRANCH', false)
    }
  },
  async deleteSpeacialtyId({commit}, id) {
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.delete(`${pathUrl}/specialty/${id}`)
      commit('SET_IS_LOADING', false)
      return true
    } catch {
      commit('SET_IS_LOADING', false)

      return false
    }
  },
  resetFilter({commit}) {
    commit('RESET_STATE_HOPSPITAL_FILTER')
  },
  resetSpecialtyFilter({commit}) {
    commit('RESET_STATE_DEPARTMENT_FILTER')
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },

  async setPrefix({commit}) {
    let data = await client.secureHttpClient.get(`${pathUrl}/prefix`)
    commit('SET_PREFIX_LIST', data.data)
  },
  // async setSpecialty({commit}, isDetail) {
  //   const {filterHospital, filterDepartment} = state
  //   try {
  //     let data = await client.secureHttpClient.get(
  //       `${pathUrl}/department/specialties?department_id=${filterDepartment}&org_codename=${filterHospital}`
  //     )
  //     if (isDetail) {
  //       commit('SET_SPECIALTY_DETAIL', data.data)
  //     } else {
  //       commit('SET_SPECIALTY_LIST', data.data)
  //     }
  //   } catch (e) {
  //     if (isDetail) {
  //       commit('SET_SPECIALTY_DETAIL', [])
  //     } else {
  //       commit('SET_SPECIALTY_LIST', [])
  //     }
  //   }
  // },
  setImageDepartment({commit}, file) {
    commit('SET_IMAGE_DEPARTMENT', file)
  },
  setImageThai({commit}, file) {
    commit('SET_IMAGE_THAI', file)
  },
  setImageEng({commit}, file) {
    commit('SET_IMAGE_ENG', file)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetSearchAndFilter({commit}) {
    commit('RESET_SEARCH_AND_FILTER')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0]
    }
    commit('SET_FILTER_STATUS', selected)
  },
  setFilterCategory({commit}, status) {
    commit('SET_FILTER_CATEGORY', status)
  },
  setFilterHospital({commit}, status) {
    commit('SET_FILTER_HOSPITAL', status)
  },
  setFilterDepartment({commit}, status) {
    commit('SET_FILTER_DEPARTMENT', status)
  },
  setFilterSpecialty({commit}, status) {
    commit('SET_FILTER_SPECIALTY', status)
  },
  setShowPreview({commit}, status) {
    // isPreview:false,
    commit('SET_SHOW_PREVIEW', status)
  },
  setShowEditOrder({commit}, status) {
    commit('SET_SHOW_EDIT_ORDER', status)
  },
  async setEditOrder({commit}, item) {
    const indexNo = state.sortableList.findIndex(x => x.id === item.id)
    commit('SET_EDIT_ORDER_OBJECT', {
      ...item,
      indexNo,
      total: state.sortableList.length
    })
    commit('SET_SHOW_EDIT_ORDER', true)
  },
  setTextSearch({commit}, text) {
    if (text === state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  async setDepartmentId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/department/${id}`)
    if (data) {
      commit('SET_DEPARTMENT_OBJECT', data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  async setdepartmentPreview({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/department/${id}`)
    if (data) {
      commit('SET_DEPARTMENT_OBJECT', data.data)
      commit('SET_SHOW_PREVIEW', true)
    }
    commit('SET_IS_LOADING', false)
  },
  /// EDIT call
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(id => {
      return id.id
    })
    let body = {ids: ids, status: status}
    await client.secureHttpClient.patch(
      `${pathUrl}/department/bulk-update`,
      body
    )
    dispatch('fetch')
    commit('SET_UPDATE_ID', [])
  },
  async updateAllStatus({commit}, {ids, status}) {
    try {
      ids = ids.map(id => {
        return id.id
      })
      let body = {
        ids: ids,
        status: status
      }
      commit('SET_LOAD', true)
      await client.secureHttpClient.patch(
        `${pathUrl}/department/bulk-update`,
        body
      )
      commit('SET_LOAD', false)
      return true
    } catch (e) {
      commit('SET_LOAD', false)
      return false
    }
  },
  async upload(_, payload) {
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/uploadImage`,
        payload
      )
      return data.data.url
    } catch (e) {
      Vue.$log.error(e)
      return ''
    }
  },
  async checkIsUnderused({commit}, deleteId) {
    try {
      commit('SET_IS_LOADING', true)
      const data = await client.secureHttpClient.get(
        `${pathUrl}/department/${deleteId}/check`
      )
      commit('SET_IS_LOADING', false)
      return data.data.is_underused
    } catch (e) {
      Vue.$log.error(e)
      return false
    }
  },
  async deletePkg({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/department/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  // API calls
  async fetch({commit}) {
    const {
      currentPage,
      pageSize,
      textSearch,
      filterStatus,
      filterHospital
    } = state
    const params = {
      page: currentPage,
      limit: pageSize,
      q: textSearch,
      ...(filterStatus !== '' && {status: filterStatus}),
      ...(filterHospital !== '' && {org_codename: filterHospital})
    }
    commit('SET_IS_LOADING', true)
    // const   {page, search, limit, status, category, sortBy, sortDesc} = state
    try {
      if (filterHospital && filterHospital != '') {
        let data = await client.secureHttpClient.get(`${pathUrl}/department`, {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        })
        commit('SET_TOTAL', data.data.total)
        commit('SET_DEPARTMENT_LIST', data.data.results)
      } else {
        commit('SET_TOTAL', 0)
        commit('SET_DEPARTMENT_LIST', [])
      }
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_DEPARTMENT_LIST', [])
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchSortableList({commit}) {
    const {filterHospital} = state

    commit('SET_IS_LOADING', true)
    try {
      const params = {
        listType: 'sorting',
        ...(filterHospital !== '' && {org_codename: filterHospital})
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/department`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_SORTABLE_TOTAL', data.data.results.length)
      commit('SET_SORTABLE_LIST', data.data.results)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async changeStatus({dispatch}, {status, ids}) {
    try {
      await client.secureHttpClient.patch(`${pathUrl}/department/bulk_update`, {
        departments: ids,
        status: status
          ? departmentStatusEnum.INACTIVE
          : departmentStatusEnum.ACTIVE
      })
      dispatch('fetch')
      return true
    } catch (e) {
      dispatch('fetch')
      Vue.$log.error(e)
      return false
    }
  },
  setStyleImage({commit}) {
    commit('SET_STYLE_CONTENT')
  },

  async updateDepartment({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.put(`${pathUrl}/department/${id}`, pkg)
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      const errMessage = e.response.data.message || 'Incomplete information'
      Vue.$log.error(e)
      if (
        e.response.data.message ===
        '"Department Code" already exists in organization'
      ) {
        state.codeErrorMessage = 'Centrix department code already exists'
      }
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    try {
      const create = await client.secureHttpClient.post(
        `${pathUrl}/doctor/department`,
        pkg
      )
      // commit('RESET_STATE')
      commit('SET_IS_LOADING', false)
      commit('SET_DEPARTMENT_ID', create.data.id)
      return true
    } catch (e) {
      const errMessage = e.response.data.message || 'Incomplete information'
      Vue.$log.error(e)
      if (
        e.response.data.message ===
        '"Department Code" already exists in organization'
      ) {
        state.codeErrorMessage = 'Centrix department code already exists'
      }
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  async fetchDepartment(
    {commit},
    {page, search, limit, status, category, sortBy, sortDesc}
  ) {
    try {
      commit('SET_LOAD', true)
      let queryString = `?page=${page}&&limit=${limit}&&q=${search}&&status=${status}&&category=${
        category[0].text
      }&orderBy=${sortBy[0] ? sortBy[0] : 'update_date'}&sort=${
        sortDesc[0] ? 'DESC' : 'ASC'
      }`
      let data = await client.secureHttpClient.get(
        `${pathUrl}/department${queryString}`
      )
      if (data.data) {
        commit('SET_DATA', data)
      }
      commit('SET_LOAD', false)
    } catch (e) {
      commit('SET_LOAD', false)
    }
  },
  async setAllDepartment({commit}) {
    const {filterHospital} = state
    let data = await client.secureHttpClient.get(
      `${pathUrl}/doctor/departments?lang=th&org_codename=${filterHospital}`
    )
    const departmentsInHighlight = data.data.data.filter(
      obj => obj.is_highlight == 1
    )
    commit('SET_HIGHLIGHTS', {
      depts: data.data.data,
      deptsInHighlight: departmentsInHighlight
    })
  },
  async updateHighlights({dispatch}, ids) {
    const {filterHospital} = state
    let body = {
      departments: ids,
      org_codename: filterHospital,
      type: 'highlight'
    }
    await client.secureHttpClient.patch(
      `${pathUrl}/department/bulk_update`,
      body
    )
    dispatch('setAllDepartment')
  },
  async setHighlightDepartments({commit}, data) {
    commit('SET_HIGHLIGHTS', data)
  },
  resetDepartmentList({commit}) {
    commit('RESET_DEPARTMENT_LIST')
  },
  async fetchCategory({commit}) {
    //TODO: debug data input
    // try {
    //   let category = await client.secureHttpClient.get(`${pathUrl}/category`)
    //   commit('SET_CATEGORY', category)
    // } catch (e) {
    commit('SET_CATEGORY', {
      data: [
        {id: 1, title: 'Full Time'},
        {id: 0, title: 'Part Time'}
      ]
    })
    // }
  },
  //   async fetchDepartment({commit}) {
  //     //TODO: debug data input
  //     try {
  //       let department = await client.secureHttpClient.get(`${pathUrl}/category`)
  //       commit('SET_DEPARTMENT', department)
  //     } catch (e) {
  //       commit('SET_DEPARTMENT', {data: [{"id": 0,"title":"Part Time"},{"id": 1,"title":"Full Time"}]})
  //     }
  //   }
  async setSymptomaticItems({commit}) {
    const {filterHospital} = state
    let data = await client.secureHttpClient.get(
      `${pathUrl}/symptomatic/filter?org_codename=${filterHospital}`
    )
    let results = data.data.data.map(object => {
      return {text: object.name, value: object.id}
    })
    commit('SET_SYMPTOMATIC_LIST', results)
  },
  async setAlias({commit}, alias) {
    const {filterHospital} = state
    const params = {
      alias: alias,
      db: 'department',
      org_codename: filterHospital,
      id: state.departmentObject.id
    }
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/alias/checked`,
        {
          ...params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      if (data.data.success) {
        commit('SET_ALIAS', data.data.data)

        let pageLink = `${data.data.website}/medical-center/${data.data.data}`
        let pageLinkEn = `${data.data.website}en/medical-center/${data.data.data}`

        if (data.data.website.endsWith('/')) {
          pageLink = `${data.data.website}medical-center/${data.data.data}`

          pageLinkEn = `${data.data.website}en/medical-center/${data.data.data}`
        }

        commit('SET_PAGELINK', pageLink)
        commit('SET_PAGELINK_EN', pageLinkEn)
      }
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  setImageError({commit}, data) {
    commit('SET_IMAGE_ERROR', data)
  },
  setCoverImageThai({commit}, data) {
    commit('SET_COVER_IMAGE', {lang: 'th', file: data})
  },
  setCoverImageEn({commit}, data) {
    commit('SET_COVER_IMAGE', {lang: 'en', file: data})
  },
  setMetaImageError({commit}, data) {
    commit('SET_META_IMAGE_ERROR', data)
  },
  setMetaImage({commit}, data) {
    commit('SET_META_IMAGE', data)
  }
}

// Mutations
const mutations = {
  SET_HIGHLIGHTS(state, {depts, deptsInHighlight}) {
    const deptWithAdded = []
    for (let dept of depts) {
      if (deptsInHighlight.find(rm => rm.id === dept.id)) {
        deptWithAdded.push({...dept, ...{disabled: true}})
      } else {
        deptWithAdded.push({...dept, ...{disabled: false}})
      }
    }
    state.highlights = deptsInHighlight
    state.allDepartment = deptWithAdded
  },
  SET_DEPARTMENT_ID(state, id) {
    state.departmentObject.id = id
  },
  SET_COVER_IMAGE(state, {lang, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.departmentObject.contents[0].cover_image = base64
        }
        if (lang === 'en') {
          state.departmentObject.contents[1].cover_image = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_META_IMAGE_ERROR(state, {lang, type, isError, message}) {
    state.isMetaImageError[type][lang] = isError
    state.metaImageErrorMessage[type][lang] = message
  },
  SET_META_IMAGE(state, {lang, type, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.departmentObject.meta[type][0].image = base64
        }
        if (lang === 'en') {
          state.departmentObject.meta[type][1].image = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_ORG_LIST(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  SET_DEPARTMENT_LIST(state, dept) {
    state.departmentList = dept
  },
  SET_SPECIALTY_LIST(state, spect) {
    try {
      let results = spect.data.map(object => {
        return {text: object.name, value: object.id}
      })
      results = insert(results, 0, {text: 'All', value: ''})
      state.specialtyObject = results
    } catch (e) {
      state.specialtyObject = [{text: 'All', value: ''}]
    }
  },
  SET_PREFIX_LIST(state, prefix) {
    let results = prefix.data.map(object => {
      return {text: object.value_th, value: object.id, text_en: object.value_en}
    })
    state.prefixObject = results
  },
  SET_ORG_DETAIL(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  SET_DEPARTMENT_DETAIL(state, dept) {
    let results = dept.data.map(object => {
      return {text: object.name, value: object.id}
    })
    state.departmentObject = results
  },
  SET_SPECIALTY_DETAIL(state, spect) {
    try {
      let results = spect.data.map(object => {
        return {text: object.name, value: object.id}
      })
      state.specialtyObject = results
    } catch (e) {
      state.specialtyObject = []
    }
  },
  RESET_STATE(state) {
    state.departmentObject = defaultState().departmentObject
    state.codeErrorMessage = defaultState().codeErrorMessage
    state.pageLink = defaultState().pageLink
    state.pageLinkEn = defaultState().pageLink
  },
  RESET_SEARCH_AND_FILTER(state) {
    state.departmentList = defaultState().departmentList
    state.filterStatus = defaultState().filterStatus
    state.textSearch = defaultState().textSearch
  },
  RESET_STATE_HOPSPITAL_FILTER(state) {
    state.filterDepartment = defaultState().filterDepartment
    state.filterSpecialty = defaultState().filterSpecialty
  },
  RESET_STATE_DEPARTMENT_FILTER(state) {
    state.filterSpecialty = defaultState().filterSpecialty
  },
  RESET_LIST_STATE(state) {
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.departmentList = []
    state.filterStatus = defaultState().filterStatus
    state.filterHospital = defaultState().filterHospital
    state.filterDepartment = defaultState().filterDepartment
    state.filterSpecialty = defaultState().filterSpecialty

    state.promotionOnly = defaultState().promotionOnly
    state.sortBy = defaultState().sortBy
  },
  SET_STYLE_CONTENT(state) {
    state.departmentObject.contents[0].description = state.departmentObject.contents[0].description
      .toString()
      .split('<img')
      .join('<img width="100%" height="auto"')
    state.departmentObject.contents[1].description = state.departmentObject.contents[1].description
      .toString()
      .split('<img')
      .join('<img width="100%" height="auto"')
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_ERROR_MESSAGE(state, message) {
    state.errorMessage = message
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_IS_LOADING_BRANCH(state, isLoading) {
    state.isLoadingBranch = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_TAB_STATUS(state, status) {
    state.statusTab = status
  },
  SET_CATEGORY(state, category) {
    let results = category.data.map(object => {
      return {text: object.title, value: object.id}
    })
    results = insert(results, 0, {text: 'All', value: ''})
    state.category = results
  },
  SET_DEPARTMENT(state, category) {
    let results = category.data.map(object => {
      return {text: object.title, value: object.id}
    })
    state.departmentObject = results
  },
  SET_SPECIALTY(state, category) {
    let results = category.data.map(object => {
      return {text: object.title, value: object.id}
    })
    state.specialtyObject = results
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_FILTER_HOSPITAL(state, status) {
    state.filterHospital = status
  },
  SET_FILTER_DEPARTMENT(state, status) {
    state.filterDepartment = status
  },
  SET_FILTER_SPECIALTY(state, status) {
    state.filterSpecialty = status
  },
  SET_FILTER_CATEGORY(state, status) {
    state.filterCategory = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_SORTABLE_TOTAL(state, total) {
    state.sortableTotal = total
  },
  SET_DEPARTMENTS_LIST(state, department) {
    state.departmentList = department
  },
  SET_SORTABLE_LIST(state, banners) {
    state.sortableList = banners
  },
  SET_DEPARTMENT_OBJECT(state, pkg) {
    state.departmentObject = pkg.data
    state.departmentObject.org_codename = pkg.data.org[0].org_codename
    state.filterHospital = state.departmentObject.org_codename
    state.filterDepartment = state.departmentObject.department_id
    state.filterSpecialty = state.departmentObject.specialty_id
    state.departmentObject.specialties = pkg.data.specialties.map(obj => {
      obj.contents = obj.contents.sort((thai, eng) =>
        thai.code < eng.code ? 1 : -1
      )
      return obj
    })
  },
  SET_EDIT_ORDER_OBJECT(state, banner) {
    state.editOrderObject = banner
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_IMAGE_DEPARTMENT(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.departmentObject.image = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_IMAGE_THAI(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.departmentObject.contents[0].logo = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_IMAGE_ENG(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.departmentObject.contents[1].logo = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  },
  SET_SHOW_EDIT_ORDER(state, status) {
    state.isEditOrder = status
  },
  SET_SYMPTOMATIC_LIST(state, symptomaticItems) {
    state.symptomaticItems = symptomaticItems
  },
  SET_ALIAS(state, alias) {
    state.departmentObject.alias = alias
  },
  SET_PAGELINK(state, pageLink) {
    state.pageLink = pageLink
  },
  SET_PAGELINK_EN(state, pageLinkEn) {
    state.pageLinkEn = pageLinkEn
  }
}
const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
]
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
